import React from "react";
import './page1.css';
import sprint from './sprint.png';
import sprint2 from './sprint2.png';
import notes1 from './notes1.png';
import bubbles from './bubbles.png';
import entrypoints from './entrypoints.png';
import drag from './drag.mov';
import mention from './mention.mov';
import before from './before.mov';
import after from './after.mov';
import xolo from './xolo.mp4';
import tt from './tt.png';
import eastereggs from './eastereggs.png';
import tweets from './tweets.png';


const Page1 = () => {
    return (
        <div className="caseStudyContainer">
            <h1>Differentiating Reels</h1>
            <h2>Why differentiate?</h2>
            <p className="caseStudy">Reels (Instagram's short-form videos) are one of the fastest growing products among teens. However, overall usage was still low and there wasn't a clear reason to use Reels over competitors like TikTok and Youtube shorts. As this became a key company priority, we set out to develop a video products that would differentiate us from other SFV on the market and give teens a reason to switch to Instagram.</p>

            <h2>Instagram is where your friends are</h2>
            <p className="caseStudy">How do you differentiate something that's already so good and so addicting that it has replaced Netflix & Chill with "i love a good brainrot" and got teens too engaged to put their phones down? It's an ambiguous space and a very hard problem with moral implications. Our goal wasn't to make teens more addicted to their phones, our goal was to provide some type of value beyond solo, brainrot entertainment. Our intuition was to anchor our work in what Instagram does best — helping you connect with your friends. </p>

            <h2>Design sprint</h2>
            <p className="caseStudy">Like the beginning of many good things, we started with a design sprint. An Instagram design sprint is a special beast. A few designers come together and churn out 30-50 ideas in the form of hifi mocks, prototypes, and decks. Review, iterate, rinse & repeat daily.</p>

            <img className="process" src={sprint}/>

            <p className="caseStudy">I started broad and looked at some crazy things. Messaging with friends on on top of reels, commenting on cutouts, crowd sourcing reels via stories stickers, etc.</p>

            <img className="process" src={sprint2}/>

            <p className="caseStudy">But I kept coming back to an idea I played around with on Explore a few months before. What if you could "bump" your favorite reels for your friends? What if you could add a joke, or a hot take? It was a new way to share reels with friends in-situ, directly in the reels tab, feed, or Explore. </p>

            <img className="process" src={notes1}/>

            <p className="caseStudy">I explored a spectrum of visual treatments for this "repost bubble". What resonated the most was putting the person and their thoughts front and center. The most simple, fun, and people-centric way to do this was to extend the Instagram notes system to posts and reels. After three weeks of sprinting, we aligned on the concept with leadership. The next week, we fleshed out a detailed proposal and got approval to build. A month later, we started company dogfooding.</p>

            <img className="process" src={bubbles}/>

            <h2>Progress isn't linear</h2>

            <p className="caseStudy">While it feels amazing to move so quickly, progress isn’t linear. What followed those quick milestones, was a long, ambiguous period of learning and iterating that theoretically goes on forever. In this time, we designed some things that did work, like dragging to minimize interaction when notes are blocking content and @mentions in notes.</p>

            <div className="videocontainer">
              <video className="processvideo" loop muted autoPlay playsInline src={drag} ></video>
              <video className="processvideo" loop muted autoPlay playsInline src={mention} ></video>
            </div>

            <p className="caseStudy">We tried some things that didn’t work, but helped us learn like a persistent heart affordance and floating mimicry. We iterated and ended up shipping an evolution of both of these.</p>

            <p className="caseStudy">And some things will just take you for a massive loop. We public tested over seven entry points for content notes, and ended up shipping the control we started with. But we had to do this work to be confident and defensible in where we were.</p>

            <img className="process" src={entrypoints}/>

            <p className="caseStudy">At the end of the day, we just focused on making the core experience better. A month before launch, we made a massive craft push. We decided to redesign every note bubble, as well as the core creation and reply flows for content notes. Immaculate timing right. But before and after were like night and day, the experience feels native on content and so much more polished.</p>

            <div className="videocontainer">
              <video className="processvideo" loop muted autoPlay playsInline src={before} ></video>
              <video className="processvideo" loop muted autoPlay playsInline src={after} ></video>
            </div>

            <p className="caseStudy">This long period of learning was marked by three public tests, two in late 2023 and one in May of 2024, we spent many cycles learning and iterating before getting approval to launch.</p>

            <h2>Launch</h2>

            <p className="caseStudy">I partnered closely with marketing, comms, and brand on our go-to-market strategy. We partnered with Xolo Maridueña of Cobra Kai and a group of teen resonant creators on IG/TT to launch through organic channels, App Store/Play Store, and traditional media. </p>

            <div className="videocontainer">
              <video className="processvideo" loop muted autoPlay playsInline src={xolo} ></video>
            </div>

            <img className="process" src={tt}/>

            <p className="caseStudy">We had a 7-day education flow and a robust notification flow to help introduce people to this feature for the first time. We also built a Notes Easter Egg system for special moments like brat (held due to political developments), the Olympics, Sabrina Carpenter's new album launch, and "demure".</p>

            <img className="process" src={eastereggs}/>

            <p className="caseStudy">And with that, we launched content notes globally. The post launch period was another type of crazy. We have several SEVs, some driven by the inappropriate behavior of US teens exploiting ranking loopholes we didn’t know we had and global network effects that we could never have detected in country tests. In the first few days post launch, we were able to see that people were getting it and people were having fun, and that’s the most rewarding thing.</p>

            <img className="process" src={tweets}/>



        </div>
    );
};

export default Page1;
