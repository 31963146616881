import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import './home.css';

import tomato1 from '../work/tomato-outline.svg';
import tomato2 from '../work/tomato-filled.svg';
import smiley from '../work/smile.svg';
import bread from '../work/bread.svg';
import brat from '../work/brat.mov';
import messenger from '../work/messenger.mov';
import vector from '../work/vector.svg';
import yc from '../work/yc.png';
import zoom from '../work/zoom.mov';
import kp from '../work/kp.jpg';
import houzz from '../work/houzz.png';

const Home = () => {
  const [isHovered, setIsHovered] = useState(null)
  const [video, setVideo] = useState(brat)
  const [img, setImg] = useState(null)

  function handleMouseOver(e) {
    switch (e.target.id) {
        case "instagram":
            setIsHovered(1)
            document.getElementById('video').style.visibility = "visible";
            document.getElementById('img').style.visibility = "hidden";
            setVideo(brat)
            break
        case "messenger":
            setIsHovered(2)
            document.getElementById('video').style.visibility = "visible";
            document.getElementById('img').style.visibility = "hidden";
            setVideo(messenger)
            break
        case "vector":
            setIsHovered(3)
            document.getElementById('video').style.visibility = "hidden";
            document.getElementById('img').style.visibility = "visible";
            setImg(vector)
            break
        case "zoom":
            setIsHovered(4)
            document.getElementById('video').style.visibility = "visible";
            document.getElementById('img').style.visibility = "hidden";
            setVideo(zoom)
            break
        case "yc":
            setIsHovered(5)
            document.getElementById('video').style.visibility = "hidden";
            document.getElementById('img').style.visibility = "visible";
            setImg(yc)
            break
        case "kp":
            setIsHovered(6)
            document.getElementById('video').style.visibility = "hidden";
            document.getElementById('img').style.visibility = "visible";
            setImg(kp)
            break
          break;
        case "houzz":
            setIsHovered(7)
            document.getElementById('video').style.visibility = "hidden";
            document.getElementById('img').style.visibility = "visible";
            setImg(houzz)
            break
     }
   }

  function changeBackground(color, id, bool) {
    document.body.style.background = color;
    if (id) {
      if (bool) {
        document.getElementById(id).style.visibility = "visible";
      } else {
        document.getElementById(id).style.visibility = "hidden";
      }
    }
  }

  window.onload=function(){
    var tomato1 = document.getElementById('tomato-outline');
    tomato1.addEventListener("mouseenter", function () {changeBackground('#FFFFFF', 'tomato-filled', 1)});
    tomato1.addEventListener("mouseout", function () {changeBackground('#FFFFFF', 'tomato-filled')});
  };

  return (
    <div>
      <div>
        <h1>Kathy Zhou is a product designer at Instagram, based in Brooklyn.</h1>

        <div className="body">
          <div className="bio">
            <p>I craft new features that help teens have fun <img id="smiley" src={smiley}/> on <b id="instagram" className="cv" onMouseOver={handleMouseOver}>Instagram</b>. I work across the core surfaces and Threads, with a focus on Reels.</p>
            <p>Previously, I worked on AR/VR at <b id="messenger" className="cv" onMouseOver={handleMouseOver}>Messenger</b>. Dabbled in crypto while freelancing for <b id="vector"className="cv" onMouseOver={handleMouseOver}>VectorDAO</b>. Helped people stay connected through the pandemic at <b id="zoom" className="cv" onMouseOver={handleMouseOver}>Zoom</b>. Built a startup at <b  id="yc" className="cv" onMouseOver={handleMouseOver}>Y Combinator</b>. Was a fellow at <b id="kp" className="cv" onMouseOver={handleMouseOver}>Kleiner Perkins</b>. Worked on interior design e-commerce at <b id="houzz" className="cv" onMouseOver={handleMouseOver}>Houzz</b>.</p>
            <p>I love fresh produce, iced matcha, and bread <img id="bread" src={bread}/>.</p>

            <div className="socials">
              <p><a className="social-link" href="https://www.twitter.com/kathytzhou">TW ↗</a>
              <a className="social-link" href="https://instagram.com/kathytzhou">IG ↗</a>
              <a className="social-link" href="https://www.linkedin.com/in/kathytzhou">LI ↗</a>
              <a className="social-link" href="mailto:kathytzhou@gmail.com">SAY HI ↗</a></p>
            </div>
          </div>

          <div className="work">
            <video id="video" loop muted autoPlay playsInline src={video} ></video>
            <img id="img" src={img}/>
          </div>
        </div>

        {/*<Link to="/page1">
          <p> GO TO PAGE 1 ↗ </p>
        </Link>*/}

      </div>
    </div>
  );
};

export default Home;
