import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';

import './App.css';

import Home from './pages/home';
import Page1 from './pages/page1';

// Assets
import tomato1 from './work/tomato-outline.svg';
import tomato2 from './work/tomato-filled.svg';
import smiley from './work/smile.svg';
import bread from './work/bread.svg';
import brat from './work/brat.mov';
import messenger from './work/messenger.mov';
import vector from './work/vector.svg';
import yc from './work/yc.png';
import zoom from './work/zoom.mov';
import kp from './work/kp.jpg';
import houzz from './work/houzz.png';

function App() {
  const [isHovered, setIsHovered] = useState(null)
  const [video, setVideo] = useState(brat)
  const [img, setImg] = useState(null)

  function handleMouseOver(e) {
    switch (e.target.id) {
        case "instagram":
            setIsHovered(1)
            document.getElementById('video').style.visibility = "visible";
            document.getElementById('img').style.visibility = "hidden";
            setVideo(brat)
            break
        case "messenger":
            setIsHovered(2)
            document.getElementById('video').style.visibility = "visible";
            document.getElementById('img').style.visibility = "hidden";
            setVideo(messenger)
            break
        case "vector":
            setIsHovered(3)
            document.getElementById('video').style.visibility = "hidden";
            document.getElementById('img').style.visibility = "visible";
            setImg(vector)
            break
        case "zoom":
            setIsHovered(4)
            document.getElementById('video').style.visibility = "visible";
            document.getElementById('img').style.visibility = "hidden";
            setVideo(zoom)
            break
        case "yc":
            setIsHovered(5)
            document.getElementById('video').style.visibility = "hidden";
            document.getElementById('img').style.visibility = "visible";
            setImg(yc)
            break
        case "kp":
            setIsHovered(6)
            document.getElementById('video').style.visibility = "hidden";
            document.getElementById('img').style.visibility = "visible";
            setImg(kp)
            break
          break;
        case "houzz":
            setIsHovered(7)
            document.getElementById('video').style.visibility = "hidden";
            document.getElementById('img').style.visibility = "visible";
            setImg(houzz)
            break
     }
   }

  function changeBackground(color, id, bool) {
    document.body.style.background = color;
    if (id) {
      if (bool) {
        document.getElementById(id).style.visibility = "visible";
      } else {
        document.getElementById(id).style.visibility = "hidden";
      }
    }
  }

  window.onload=function(){
    var tomato1 = document.getElementById('tomato-outline');
    tomato1.addEventListener("mouseenter", function () {changeBackground('#FFFFFF', 'tomato-filled', 1)});
    tomato1.addEventListener("mouseout", function () {changeBackground('#FFFFFF', 'tomato-filled')});
  };

  return (
    <div className="App" id="App">
      <div className="content">

        <Link to="/">
        <div id="container">
          <img id="tomato-outline" src={tomato1}/>
          <img id="tomato-filled" src={tomato2}/>
        </div>
        </Link>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/instagram" element={<Page1 />} />
        </Routes>

      </div>
    </div>
  );
}

export default App;
